.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 90vh;
    width: 100vw;



}

.loading-lottie {
    z-index: 100;
}