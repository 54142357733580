#modal-success, #modal-delete, #modal-client, #modal-information-request {
    z-index: 10;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;


    margin: auto auto;
    padding: 3%;
    border-radius: 5px;

    color: #fff;
    text-align: center;
}

#modal-client {
    background-color: lightgreen;

    width: 33vw;
    height: 25vh;
}

#modal-success {
    background-color: lightgreen;

    width: 33vw;
    height: 15vh;
}

#modal-delete {
    background-color: lightcoral;

    width: 33vw;
    height: 15vh;
}

#modal-information-request {
    background-color: lightblue;

    width: 40vw;
    height: 65vh
}

.close-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#modal-success div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
}

#modal-success a {}