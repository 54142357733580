/* IMPORT COMPONENTS STYLE  */
@import url("./Shared/components/AlertMessage/AlertMessage.css");
@import url("./Shared/components/Modals/Modals.css");
@import url("./Shared/components/LoadingWrapper/LoadingWrapper.css");

/* IMPORT PAGE STYLE  */
@import url("./Client/Home/Home.css");
@import url("./Client/AboutUs/AboutUs.css");
@import url("./Client/ProductSingle/ProductSingle.css");
@import url("./Backoffice/ContactBack/ContactBack.css");



@import url("./Backoffice/Products/Products.css");


body {
    max-width: 100vw;
}

img {
    width: 100%;
    height: 100%;
}