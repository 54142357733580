.ck-editor {
    max-height: 40vh;
    overflow-y: scroll;

}

.feature-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.feature-input-container .input-group {
    width: 40%;
}